/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// import ThemeProvider, { fetchData, ThemeContext } from "../context/ContextFile";
import { FiFacebook, FiTwitter, FiYoutube } from 'react-icons/fi';
import { FaInstagram, FaRegCircle } from 'react-icons/fa6';
// import RegisterModal from "./RegisterModal";
import UserHeaderCss from '../UserHeader/UserHeader.css';

function UserHeader() {
  const navigate = useNavigate();
  const [open, setOPen] = useState(false);
  // let { setFilterListing } = useContext(ThemeContext);
  const [modalShow, setModalShow] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isRegister, setIsRegister] = React.useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const currentUser = JSON.parse(localStorage.getItem('currentuser'));

  const url = 'https://flyinnew.dev-bt.xyz/';

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsDisabled(true);
    }
  }, []);

  const [data, setdata] = useState(JSON.parse(localStorage.getItem('data')));
  console.log(data?.image, 'image');
  const logOut = () => {
    localStorage.removeItem('data');
    localStorage.removeItem('token');
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Logout successful:',
      showConfirmButton: false,
      timer: 800,
    }).then(() => {
      window.location.reload();
      navigate('/');
    });
  };

  const handleMouseEnter = () => {
    setOPen(true);
  };

  const handleMouseLeave = () => {
    setOPen(false);
  };

  return (
    <>
      <div className="myst">
        {/* <div className="header-upper" style={{alignItems:'cenetr'}}>
          <div className="footer-social" style={{marginTop:0,}}>
            <ul style={{ width: "240px", display: "flex", justifyContent: "space-between", marginLeft: "20px", alignItems:'center', }} className="no-list-style">
              <li>
                <Link to="https://www.facebook.com/FlyInnLLC/" target="_blank">
                  <FiFacebook style={{ fontSize: "20px", color: "#4f5962" }} />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/FlyInnLLC" target="_blank">
                  <FiTwitter style={{ fontSize: "20px", color: "#4f5962" }} />
                </Link>
              </li>
              <li>
                <Link to="https://www.instagram.com/flyinnllc/" target="_blank">
                  <FaInstagram style={{ fontSize: "20px", color: "#4f5962" }} />
                </Link>
              </li>
              <li>
                <Link to="https://www.youtube.com/@FLY-INN" target="_blank">
                  <FiYoutube
                    style={{ fontSize: "20px", color: "#4f5962" }} />
                </Link>
              </li>
            </ul>
          </div>
        
       
        </div> */}
        <header className="main-header mt-md-0 mt-lg-0 d-md-block d-lg-block d-none" style={{ marginTop: '50px' }}>
          <div style={{ marginLeft: '67px' }}>
            <a className="logo-holder " href={url}>
              <img
                style={{
                  width: '100%',
                  height: '82px',
                }}
                src="/logo/favicon.svg"
                alt=""
              />
            </a>
          </div>
          <div className="nav-holder main-menu">
            <nav>
              <ul className="no-list-style">
                <li>
                  <a href={url} className="link">
                    Home
                  </a>
                </li>
                <li>
                  <a href={`${url}map`} target="_blank" className="link">
                    Map
                  </a>
                </li>
                <li>
                  <a href="#" className="">
                    Explore <i className="fa fa-caret-down" />
                  </a>
                  {/*second level */}
                  <ul>
                    <li>
                      <a href={`${url}by-state-airport`} target="_blank">
                        {' '}
                        By State / Airport
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        By Lodging
                        <i className="fa fa-caret-down" />
                      </a>
                      {/*third  level  */}
                      <ul
                        className="specific-ul"
                        style={{
                          overflowY: 'scroll',
                          height: '60vh',
                          overflowX: 'hidden',
                        }}
                      >
                        <li>
                          <a href={`${url}by-dwelling/details/Apartment`} target="_blank">
                            Apartment
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Bed_Breakfast`} target="_blank">
                            Bed & Breakfast
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Beachfront`} target="_blank">
                            Beachfront
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Cabin`} target="_blank">
                            Cabin
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Campsite`} target="_blank">
                            Campsite
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Cando`} target="_blank">
                            Condo
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Farm`} target="_blank">
                            Farm
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Hangar`} target="_blank">
                            Hangar
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Hangar_Home`} target="_blank">
                            Hangar Home{' '}
                          </a>
                        </li>
                        <li>
                          <a href={`${url}/by-dwelling/details/House`} target="_blank">
                            House{' '}
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Lakefront`} target="_blank">
                            Lakefront{' '}
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Loft`} target="_blank">
                            Loft
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Mansion`} target="_blank">
                            Mansion{' '}
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/Novelty`} target="_blank">
                            Novelty{' '}
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-dwelling/details/RV`} target="_blank">
                            RV{' '}
                          </a>
                        </li>
                        <li>
                          <a href={`${url}/by-dwelling/details/Tiny_Home`} target="_blank">
                            Tiny Home{' '}
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <a to="#">
                        By Space
                        <i className="fa fa-caret-down" />
                      </a>
                      {/*third  level  */}
                      <ul>
                        <li>
                          <a href={`${url}by-space/details/Entire_Place`} target="_blank">
                            Entire Place
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-space/details/Private_Room`} target="_blank">
                            Private Room
                          </a>
                        </li>
                        <li>
                          <a href={`${url}by-space/details/Shared_Room`} target="_blank">
                            Shared Room
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  <a onClick={() => fetchData()} href={`${url}listing`} className="a">
                    Stays
                  </a>
                </li>
                <li>
                  <a href="#" className="">
                    Info <i className="fa fa-caret-down" />
                  </a>
                  <ul>
                    <li>
                      <a href={`${url}about`} target="_blank">
                        About
                      </a>
                    </li>
                    <li>
                      <a href={`${url}faq`} target="_blank">
                        FAQ
                      </a>
                    </li>
                    <li>
                      <a href={`${url}philanthropy`} target="_blank">
                        Philanthropy
                      </a>
                    </li>
                    <li>
                      <a href={`${url}squawks`} target="_blank">
                        Squawks
                      </a>
                    </li>
                    <li>
                      <a href={`${url}agreements`} target="_blank">
                        Agreements
                        <i className="fa fa-caret-down" />
                      </a>
                      <ul>
                        <li>
                          <a href={`${url}terms-of-service`} target="_blank">
                            Terms and Service
                          </a>
                        </li>
                        <li>
                          <a href={`${url}privacy_policy`} target="_blank">
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                      {/*third  level end*/}
                    </li>
                    <li>
                      <a href={`${url}gallery`} target="_blank">
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a href={`${url}testimonials`} target="_blank">
                        Testimonials
                      </a>
                    </li>
                  </ul>
                  {/*second level end*/}
                </li>

                <li>
                  <a href={`${url}contact`} target="_blank" className="a">
                    Contact
                  </a>
                </li>
                <li>
                  <div>
                    <a
                      style={{
                        color: '#fff',
                        backgroundColor: '#af2322',
                        top: '0px',
                      }}
                      href={`${url}login`}
                      className={`add-list color-bg `}
                    >
                      Become a Host
                      <span style={{ paddingLeft: '0px' }}>
                        <i style={{ color: '#fff' }} className="fal fa-layer-plus"></i>
                      </span>
                    </a>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  marginLeft: '0px',
                  marginRight: '35px',
                  position: 'relative',
                  top: '0',
                }}
                className="header-user-menu"
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: data ? '120px' : '120px',
                    cursor: 'pointer',
                    paddingTop: data ? '0' : '11px',
                    fontWeight: '900',
                  }}
                  onMouseEnter={handleMouseEnter} // Open on hover
                  onMouseLeave={handleMouseLeave} // Close on unhover
                  className={`header-user-namehu-menu-visdec${open ? 'hu-menu-visdec' : ''} `}
                >
                  {data?.first_name ? data?.first_name : 'Admin'}
                  <span>
                    <img
                      style={{
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%',
                        marginRight: '10px',
                      }}
                      src={currentUser?.image}
                      alt=""
                    />
                  </span>
                </div>
                <ul
                  className={open ? 'hu-menu-vis' : null}
                  onMouseEnter={handleMouseEnter} // Open on hover
                  onMouseLeave={handleMouseLeave} // Close on unhover
                  style={{  }}
                >
                  <li>
                    <Link to="/dashboard/home"> Dashboard</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/editadmin"> Profile</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/listing"> My Listings</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/booking"> My Bookings</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/trip"> My Trips</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/message">
                      AirMail <sup>TM</sup>
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/payouts"> Payouts</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/invoices"> Invoices</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/allfavorite"> Favorites</Link>
                  </li>
                  <li>
                    <Link to="/" onClick={logOut}>
                      Log out
                    </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/spell"> Users</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/feature"> Features</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/emailmanagement"> Email Management</Link>
                  </li>

                  <li>
                    <Link to="/dashboard/blog"> Squawks</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/editpage"> Pages</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/review"> Review</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/comment"> Comment</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/media"> Media</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/cancellationPolicy"> Cancellation Policy</Link>
                  </li>
                  <li>
                    <Link to="/dashboard/category"> Category </Link>
                  </li>
                  <li>
                    <Link to="/dashboard/tags"> Tags </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div
              style={{
                marginLeft: '0px',
                marginRight: '35px',
                position: 'relative',
                top: '0',
              }}
              className="header-user-menu"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: data ? '110px' : '40px',
                  cursor: 'pointer',
                  paddingTop: data ? '0' : '11px',
                  fontWeight: '900',
                }}
                onMouseEnter={handleMouseEnter} // Open on hover
                onMouseLeave={handleMouseLeave} // Close on unhover
                className={`header-user-namehu-menu-visdec${open ? 'hu-menu-visdec' : ''} `}
              >
                  <span>
                     <img
                      style={{
                        height: "38px",
                        width: "42px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                      src={currentUser?.image}
                      alt=""
                    /> 
                  </span>
              </div>
              <ul
                className={open ? 'hu-menu-vis' : null}
                onMouseEnter={handleMouseEnter} // Open on hover
                onMouseLeave={handleMouseLeave} // Close on unhover
              >
                <li>
                  <Link to="/dashboard/home"> Dashboard</Link>
                </li>
                <li>
                  <Link to="/editadmin"> Profile</Link>
                </li>
                <li>
                  <Link to="/dashboard/listing"> My Listings</Link>
                </li>
                <li>
                  <Link to="/dashboard/booking"> My Bookings</Link>
                </li>
                <li>
                  <Link to="/dashboard/trip"> My Trips</Link>
                </li>
                <li>
                  <Link to="/dashboard/message"> Messages</Link>
                </li>
                <li>
                  <Link to="/dashboard/payouts"> Payouts</Link>
                </li>
                <li>
                  <Link to="/dashboard/invoices"> Invoices</Link>
                </li>
                <li>
                  <Link to="/dashboard/allfavorite"> Favorites</Link>
                </li>
                <li>
                  <Link to="/" onClick={logOut}>
                    Log out
                  </Link>
                </li>
                <li>
                  <Link to="/dashboard/spell"> Users</Link>
                </li>
                <li>
                  <Link to="/dashboard/facility"> Facilities</Link>
                </li>
                <li>
                  <Link to="/dashboard/amenity"> Amenities</Link>
                </li>
                <li>
                  <Link to="/dashboard/blog"> Blog</Link>
                </li>
               
              </ul>
            </div> */}

            {/* {data ? null : (
              <>
                <div
                  style={{
                    position: 'absolute',
                    right: '273px',
                    top: '46px',
                  }}
                >
                  <FaRegCircle />
                </div>
                <div
                  onClick={() => setIsRegister(!isRegister)}
                  style={{
                    marginLeft: '0px',
                    marginRight: '35px',
                    top: '32px',
                    cursor: 'pointer',
                    fontWeight: '900',
                  }}
                  className="header-user-menu"
                >
                  Register
                </div>
                <div>
                  <Link
                    style={{ color: '#fff', backgroundColor: '#af2322' }}
                    to="/login"
                    className={`add-list color-bg`}
                  >
                    Become a Host
                    <span>
                      <i className="fal fa-layer-plus"></i>
                    </span>
                  </Link>
                </div>
              </>
            )} */}
          </div>
          {/* navigation  end */}
          {/* header-search_container */}
          {/* <div className="header-search_container header-search vis-search">
            <div className="container small-container">
              <div className="header-search-input-wrap fl-wrap">
               
                <div className="header-search-input">
                  <label>
                    <i className="fal fa-keyboard" />
                  </label>
                  <input type="text" placeholder="What are you looking for ?" defaultValue="" />
                </div>
              
                <div className="header-search-input location autocomplete-container">
                  <label>
                    <i className="fal fa-map-marker" />
                  </label>
                  <input
                    type="text"
                    placeholder="Location..."
                    className="autocomplete-input"
                    id="autocompleteid2"
                    defaultValue=""
                  />
                  <Link to="#">
                    <i className="fal fa-dot-circle" />
                  </Link>
                </div>
                <div className="header-search-input header-search_selectinpt ">
                  <select data-placeholder="Category" className="chosen-select no-radius">
                    <option>All Categories</option>
                    <option>All Categories</option>
                    <option>Shops</option>
                    <option>Hotels</option>
                    <option>Restaurants</option>
                    <option>Fitness</option>
                    <option>Events</option>
                  </select>
                </div>
                <button className="header-search-button green-bg" onclick="window.location.to='#'">
                  <i className="far fa-search" /> Search{' '}
                </button>
              </div>
              <div className="header-search_close color-bg">
                <i className="fal fa-long-arrow-up" />
              </div>
            </div>
          </div> */}

          {/* {isModalOpen ? (
            <>
              <ModalLogin
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                isRegister={isRegister}
                setIsRegister={setIsRegister}
              />
            </>
          ) : null}
          {isRegister ? (
            <>
              <RegisterModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                isRegister={isRegister}
                setIsRegister={setIsRegister}
              />
            </>
          ) : null} */}
        </header>
      </div>
    </>
  );
}

export default UserHeader;
