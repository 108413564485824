/* eslint-disable import/extensions */
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import Blog from '../pages/dashboard/blog/Blog';
import AddBlog from '../pages/dashboard/blog/AddBlog';
import EditBlog from '../pages/dashboard/blog/EditBlog';
import Allfavorite from '../pages/dashboard/Favorite/AllFavorite';
import AddFavorite from '../pages/dashboard/Favorite/AddFavorite';
import EditFavorite from '../pages/dashboard/Favorite/EditFavorite';
import ViewFavorite from '../pages/dashboard/Favorite/ViewFavorite';
import ViewBlog from '../pages/dashboard/blog/ViewBlog';

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Home /> },
    { path: '/login/admin', element: <Login /> },
    { path: '/codemail', element: <CodeMail /> },
    { path: '/login/company', element: <CompanyLogin /> },
    { path: '/login/clinic', element: <ClinicLogin /> },
    { path: '/forget', element: <Register /> },
    { path: '/reset-passsword', element: <ResetPassword usertype={'company'} /> },
    { path: '/clinic-reset-passsword', element: <ResetPassword usertype={'clinic'} /> },
    {
      path: 'dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'home', element: <GeneralAnalytics /> },
        { path: 'dorm', element: <Dorm /> },
        { path: 'editdorm/:id', element: <EditDorm /> },
        { path: 'adddorm', element: <AddDorm /> },
        { path: 'element', element: <Element /> },
        { path: 'editelement/:id', element: <EditElement /> },
        { path: 'comment/:id', element: <Comment /> },
        { path: 'companyProfile', element: <CompanyProfile /> },
        { path: 'addelement', element: <AddElement /> },
        { path: 'rarity', element: <Rarity /> },
        { path: 'editrarity/:id', element: <EditRarity /> },
        { path: 'addrarity', element: <AddRarity /> },
        { path: 'spell', element: <Spell /> },
        { path: 'viewuser/:id', element: <ViewUser /> },

        { path: 'userdetail', element: <UserDetail /> },

        { path: 'addspell', element: <AddSpell /> },
        { path: 'editspell/:id', element: <EditSpell /> },
        { path: 'host', element: <Host /> },
        { path: 'addhost', element: <AddHost /> },
        { path: 'edithost/:id', element: <EditHost /> },
        { path: 'editadmin', element: <EditAdmin /> },
        { path: 'facility', element: <Facility /> },
        { path: 'addfacility', element: <AddFacility /> },
        { path: 'editfacility/:id', element: <EditFacility /> },
        { path: 'feature', element: <Amenity /> },
        { path: 'addfeature', element: <AddAmenity /> },
        { path: 'editfeature/:id', element: <EditAmenity /> },
        { path: 'listing', element: <Listing /> },
        { path: 'addlisting', element: <AddListing /> },
        { path: 'editlisting/:id', element: <EditListing /> },
        { path: 'duplicate/:id', element: <Duplicate /> },

        { path: 'viewlisitng', element: <ViewLisitng /> },
        { path: 'booking', element: <Booking /> },
        { path: 'viewbooking/:id', element: <ViewBooking /> },
        { path: 'extracharges/:id', element: <ExtraCharges /> },
        { path: 'confirmbooking', element: <ConfirmBooking /> },

        { path: 'trip', element: <Trip /> },
        { path: 'viewtrip', element: <TripView /> },
        { path: 'message', element: <Message /> },
        { path: 'message/:id', element: <Message /> },
        { path: 'review', element: <Review /> },
        { path: 'editreview/:id', element: <EditReview /> },
        { path: 'addreview', element: <AddReview /> },
        { path: 'comment', element: <Comments /> },
        { path: 'media', element: <Media /> },
        { path: 'addmedia', element: <AddMedia /> },
        { path: 'editmedia/:id', element: <EditMedia /> },
        { path: 'addcomment', element: <AddComment /> },
        { path: 'commentfilter/:id', element: <CommentFilter /> },
        { path: 'editcomment/:id', element: <EditComment /> },
        { path: 'replycomment/:id', element: <ReplyComment /> },
        { path: 'editpage', element: <EditPage /> },
        { path: 'blog', element: <Blog /> },
        { path: 'addblog', element: <AddBlog /> },
        { path: 'editblog/:id', element: <EditBlog /> },
        { path: 'viewblog/:id', element: <ViewBlog /> },

        { path: 'payouts', element: <Payouts /> },
        { path: 'payoutssearch/:id', element: <PayoutsSearch /> },
        { path: 'payoutsdetails', element: <PayoutsDetails /> },
        { path: 'invoices', element: <Invoices /> },
        { path: 'invoicesdetails', element: <InvoicesDetails /> },
        { path: 'allfavorite', element: <Allfavorite /> },
        { path: 'addfavorite', element: <AddFavorite /> },
        { path: 'editfavorite/:id', element: <EditFavorite /> },
        { path: 'viewfavorite', element: <ViewFavorite /> },
        { path: 'cancellationpolicy', element: <CencellationPolicy /> },
        { path: 'emailmanagement', element: <EmailManagement /> },
        { path: 'tags', element: <Tags /> },
        { path: 'addtag', element: <AddTags /> },
        { path: 'edittag/:id', element: <EditTags /> },
        { path: 'Category', element: <Category /> },
        { path: 'AddCategory', element: <AddCategory /> },
        { path: 'EditCategory/:id', element: <EditCategory /> },
        { path: 'AddCancellationpolicy', element: <AddCencellationPolicy /> },
        { path: 'EditCencellationPolicy/:id', element: <EditCencellationPolicy /> },
        { path: 'map', element: <Map /> },
        {
          path: 'e-commerce',
          children: [{ element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true }],
        },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const CompanyLogin = Loadable(lazy(() => import('../pages/auth/CompanyLogin')));
const ClinicLogin = Loadable(lazy(() => import('../pages/auth/ClinicLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const CodeMail = Loadable(lazy(() => import('../pages/auth/CodeMail')));

const Home = Loadable(lazy(() => import('../pages/dashboard/Home/Home')));
const Dorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/Dorm')));
const EditDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/EditDorm')));
const AddDorm = Loadable(lazy(() => import('../pages/dashboard/Subscription/AddDorm')));

const Element = Loadable(lazy(() => import('../pages/dashboard/Company/Element')));
const EditElement = Loadable(lazy(() => import('../pages/dashboard/Company/EditElement')));
const Comment = Loadable(lazy(() => import('../pages/dashboard/Comments/Comments')));
const Media = Loadable(lazy(() => import('../pages/dashboard/Media/Media')));
const AddMedia = Loadable(lazy(() => import('../pages/dashboard/Media/AddMedia')));
const EditMedia = Loadable(lazy(() => import('../pages/dashboard/Media/EditMedia')));
const AddComment = Loadable(lazy(() => import('../pages/dashboard/Comments/AddComment')));
const EditComment = Loadable(lazy(() => import('../pages/dashboard/Comments/EditComment')));
const ReplyComment = Loadable(lazy(() => import('../pages/dashboard/Comments/replycomment')));
const CommentFilter = Loadable(lazy(() => import('../pages/dashboard/Comments/CommentFilter')));
const CompanyProfile = Loadable(lazy(() => import('../pages/dashboard/Company/CompanyProfile')));
const AddElement = Loadable(lazy(() => import('../pages/dashboard/Company/AddElement')));

const Rarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/Rarity')));
const EditRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/EditRarity')));
const AddRarity = Loadable(lazy(() => import('../pages/dashboard/TermCondition.js/AddRarity')));
const EditAdmin = Loadable(lazy(() => import('../pages/dashboard/EditAdmin/EditAdmin')));

const Review = Loadable(lazy(() => import('../pages/dashboard/Review/Review')));
const AddReview = Loadable(lazy(() => import('../pages/dashboard/Review/AddReview')));
const EditReview = Loadable(lazy(() => import('../pages/dashboard/Review/EditReview')));
const Comments = Loadable(lazy(() => import('../pages/dashboard/Comments/Comments')));
const EditPage = Loadable(lazy(() => import('../pages/dashboard/Page/EditPage')));
const Spell = Loadable(lazy(() => import('../pages/dashboard/Provider/Spell')));
const UserDetail = Loadable(lazy(() => import('../pages/dashboard/Provider/UserDetail')));
const EmailManagement = Loadable(lazy(() => import('../pages/dashboard/EmailManagement/EmailManagement')));
const Tags = Loadable(lazy(() => import('../pages/dashboard/Tags/Tags')));
const AddTags = Loadable(lazy(() => import('../pages/dashboard/Tags/AddTags')));
const EditTags = Loadable(lazy(() => import('../pages/dashboard/Tags/EditTags')));
const Category = Loadable(lazy(() => import('../pages/dashboard/Category/Category')));
const AddCategory = Loadable(lazy(() => import('../pages/dashboard/Category/AddCategory')));
const EditCategory = Loadable(lazy(() => import('../pages/dashboard/Category/EditCategory')));

const AddSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/AddSpell')));
const ViewUser = Loadable(lazy(() => import('../pages/dashboard/Provider/ViewUser')));

const EditSpell = Loadable(lazy(() => import('../pages/dashboard/Provider/EditSpell')));
const CencellationPolicy = Loadable(lazy(() => import('../pages/dashboard/Cancellation/CencellationPolicy')));
const AddCencellationPolicy = Loadable(lazy(() => import('../pages/dashboard/Cancellation/AddCencellationPolicy')));
const EditCencellationPolicy = Loadable(lazy(() => import('../pages/dashboard/Cancellation/EditCencellationPolicy')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const Host = Loadable(lazy(() => import('../pages/dashboard/Host/Host')));
const AddHost = Loadable(lazy(() => import('../pages/dashboard/Host/AddHost')));
const EditHost = Loadable(lazy(() => import('../pages/dashboard/Host/EditHost')));
const Facility = Loadable(lazy(() => import('../pages/dashboard/Facility/Facility')));
const AddFacility = Loadable(lazy(() => import('../pages/dashboard/Facility/AddFacility')));
const EditFacility = Loadable(lazy(() => import('../pages/dashboard/Facility/EditFacility')));
const Amenity = Loadable(lazy(() => import('../pages/dashboard/Amenity/Amenity')));
const AddAmenity = Loadable(lazy(() => import('../pages/dashboard/Amenity/AddAmenity')));
const EditAmenity = Loadable(lazy(() => import('../pages/dashboard/Amenity/EditAmenity')));
const Listing = Loadable(lazy(() => import('../pages/dashboard/Listing/Listing')));
const AddListing = Loadable(lazy(() => import('../pages/dashboard/Listing/AddListing')));
const EditListing = Loadable(lazy(() => import('../pages/dashboard/Listing/EditListing')));
const Duplicate = Loadable(lazy(() => import('../pages/dashboard/Listing/Duplicate')));

const ViewLisitng = Loadable(lazy(() => import('../pages/dashboard/Listing/ViewLisitng')));
const Booking = Loadable(lazy(() => import('../pages/dashboard/Booking/Booking')));
const ViewBooking = Loadable(lazy(() => import('../pages/dashboard/Booking/ViewBooking')));
const ExtraCharges = Loadable(lazy(() => import('../pages/dashboard/Booking/ExtraCharge')));
const ConfirmBooking = Loadable(lazy(() => import('../pages/dashboard/Booking/ConfirmBooking')));

const Trip = Loadable(lazy(() => import('../pages/dashboard/Trip/Trip')));
const TripView = Loadable(lazy(() => import('../pages/dashboard/Trip/Tripview')));
const Map = Loadable(lazy(() => import('../pages/dashboard/Listing/MapLocation')));

const Message = Loadable(lazy(() => import('../pages/dashboard/Message/Message')));
const Payouts = Loadable(lazy(() => import('../pages/dashboard/Payouts/Payouts')));
const PayoutsSearch = Loadable(lazy(() => import('../pages/dashboard/Payouts/PayoutsSearch')));
const PayoutsDetails = Loadable(lazy(() => import('../pages/dashboard/Payouts/PayoutsDetails')));
const Invoices = Loadable(lazy(() => import('../pages/dashboard/Invoices/Invoices')));
const InvoicesDetails = Loadable(lazy(() => import('../pages/dashboard/Invoices/InvoicesDetails')));
