/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable dot-notation */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Card, Container, Grid, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFSelect, RHFTextField } from '../../../components/hook-form';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axios from '../../../utils/axios';

export default function EditFavorite() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const datas = location?.state;
  console.log(datas, 'location?.state');
  const token = localStorage.getItem('accessToken');

  const [tableData, setTableData] = useState([]);
  const [Facility, setFacility] = useState([]);

  async function AllUsers() {
    const data = await axios.get('amenity');
    setTableData(data?.data?.data);
  }
  async function AllFacility() {
    const data = await axios.get('facility');
    setFacility(data?.data?.data);
  }

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedCheckboxes((prevSelected) =>
      checked ? [...prevSelected, value] : prevSelected.filter((item) => item !== value)
    );
  };
  const [selectedCheckboxesOne, setSelectedCheckboxesOne] = useState([]);
  const handleCheckboxChangeOne = (event) => {
    const { value, checked } = event.target;
    setSelectedCheckboxesOne((prevSelected) =>
      checked ? [...prevSelected, value] : prevSelected.filter((item) => item !== value)
    );
  };

  // Multiple Input Two Code Start

  const [extraServicePrices, setExtraServicePrices] = useState([{ name: '', price: '', type: '', id: null }]);

  const handleInputChangeOne = (index, field, value) => {
    const newExtraServicePrices = [...extraServicePrices];
    newExtraServicePrices[index][field] = value;
    setExtraServicePrices(newExtraServicePrices);
  };

  const handlePlusClickOne = () => {
    setExtraServicePrices([...extraServicePrices, { name: '', price: '', type: '', id: null }]);
  };

  const handleMinusClickOne = (index, service) => {
    if (extraServicePrices?.length > 1) {
      const newExtraServicePrices = [...extraServicePrices];
      newExtraServicePrices.splice(index, 1);
      setExtraServicePrices(newExtraServicePrices);
      axios
        .delete(`/listing-extra-service-price-destroy/${service?.id}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Multiple Input Two Code End
  // Multiple Input Two Code Start

  const [bedroomsExtra, setBedroomsExtra] = useState([
    { name: '', no_of_guest: '', no_of_bed: '', bed_type: '', id: null },
  ]);

  const handleInputChangeTwo = (index, field, value) => {
    const newExtraServicePrices = [...bedroomsExtra];
    newExtraServicePrices[index][field] = value;
    setBedroomsExtra(newExtraServicePrices);
  };

  const handlePlusClickTwo = () => {
    setBedroomsExtra([...bedroomsExtra, { name: '', no_of_guest: '', no_of_bed: '', bed_type: '', id: null }]);
  };

  const handleMinusClickTwo = (index, service) => {
    if (bedroomsExtra?.length > 1) {
      const newBedrooms = [...bedroomsExtra];
      newBedrooms?.splice(index, 1);
      setBedroomsExtra(newBedrooms);
      axios
        .delete(`/listing-bedroom-destroy/${service?.id}`, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Multiple Input Two Code End

  const [images, setImages] = useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    const updated = {
      ...imageList[addUpdateIndex[0]],
      ...images[addUpdateIndex[0]],
    };
    imageList[addUpdateIndex[0]] = updated;
    setImages(imageList);
  };
  const onImageRemoves = (index, image) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    axios
      .delete(`/listing-image-destroy/${image?.id}`, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    AllUsers();
    AllFacility();
    setBedroomsExtra(datas?.listing_beds);
    setExtraServicePrices(datas?.listing_extra_service_prices);
    setImages(datas?.listing_images);
  }, []);

  const NewProviderSchema = Yup.object().shape({
    type_of_space: Yup.string(),
    title: Yup.string(),
    description: Yup.string(),
    listing_type: Yup.string(),
    no_of_bedroom: Yup.string(),
    no_of_guest: Yup.string(),
    no_of_bed: Yup.string(),
    no_of_bathroom: Yup.string(),
    no_of_room: Yup.string(),
    size: Yup.string(),
    unit_of_measure: Yup.string(),
    addi_info: Yup.string(),
    instant_booking: Yup.string(),
    nightly: Yup.string(),
    per: Yup.string(),
    weekend: Yup.string(),
    apply_weekend_price: Yup.string(),
    weekly_seven_plus: Yup.string(),
    weekly_thirty_plus: Yup.string(),
    addi_guest: Yup.string(),
    addi_guest_price: Yup.string(),
    clean_fee: Yup.string(),
    clean_fee_daily: Yup.string(),
    clean_fee_per_stay: Yup.string(),
    city_fee: Yup.string(),
    city_fee_daily: Yup.string(),
    city_fee_per_stay: Yup.string(),
    security_deposit: Yup.string(),
    tax: Yup.string(),
    apt_suite: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zipcode: Yup.string(),
    area: Yup.string(),
    country: Yup.string(),
    cancellation_policy: Yup.string(),
    min_day_booking: Yup.string(),
    max_day_booking: Yup.string(),
    check_in_after: Yup.string(),
    check_in_before: Yup.string(),
    smoking_allowed: Yup.string(),
    pet_allowed: Yup.string(),
    party_allowed: Yup.string(),
    children_allowed: Yup.string(),
    house_rule_addi_info: Yup.string(),
    address: Yup.string(),
    no_of_addi_guest: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      title: datas?.title || '',
      type_of_space: datas?.type_of_space || '',
      description: datas?.description || '',
      listing_type: datas?.listing_type || '',
      no_of_bedroom: datas?.no_of_bedroom || '',
      no_of_guest: datas?.no_of_guest || '',
      no_of_bed: datas?.no_of_bed || '',
      no_of_bathroom:  datas?.no_of_bathroom || '',
      no_of_room: datas?.no_of_room || '',
      size: datas?.size || '',
      unit_of_measure: datas?.unit_of_measure || '',
      addi_info: datas?.addi_info || '',
      instant_booking: datas?.instant_booking || '',
      nightly: datas?.nightly || '',
      per: datas?.per || '',
      weekend: datas?.weekend || '',
      apply_weekend_price: datas?.apply_weekend_price || '',
      weekly_seven_plus: datas?.weekly_seven_plus || '',
      weekly_thirty_plus: datas?.weekly_thirty_plus || '',
      addi_guest: datas?.addi_guest || '',
      addi_guest_price: datas?.addi_guest_price || '',
      clean_fee: datas?.clean_fee || '',
      clean_fee_daily: datas?.clean_fee_daily || '',
      clean_fee_per_stay: datas?.clean_fee_per_stay || '',
      city_fee:  datas?.city_fee || '',
      city_fee_daily: datas?.city_fee_daily || '',
      city_fee_per_stay: datas?.city_fee_per_stay || '',
      security_deposit: datas?.security_deposit || '',
      tax: datas?.tax || '',
      apt_suite: datas?.apt_suite || '',
      city: datas?.city || '',
      state: datas?.state || '',
      zipcode: datas?.zipcode || '',
      area: datas?.area || '',
      country: datas?.country || '',
      cancellation_policy: datas?.cancellation_policy || '',
      min_day_booking: datas?.min_day_booking || '',
      max_day_booking: datas?.max_day_booking || '',
      check_in_after: datas?.check_in_after || '',
      check_in_before: datas?.check_in_before || '',
      smoking_allowed: datas?.smoking_allowed || '',
      pet_allowed: datas?.pet_allowed || '',
      party_allowed: datas?.party_allowed || '',
      children_allowed: datas?.children_allowed || '',
      house_rule_addi_info: datas?.house_rule_addi_info || '',
      address: datas?.address || '',
      no_of_addi_guest: datas?.no_of_addi_guest || '',
    }),
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm({
    resolver: yupResolver(NewProviderSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const Data = new FormData();
    Data.append('type_of_space', data.type_of_space ? data.type_of_space : datas?.type_of_space);
    Data.append('title', data.title ? data.title : datas?.title);
    Data.append('description', data.description ? data.description : datas?.description);
    Data.append('listing_type', data.listing_type ? data.listing_type : datas?.listing_type);
    Data.append('no_of_bedroom', Number(data.no_of_bedroom) ? Number(data.no_of_bedroom) : datas?.no_of_bedroom);
    Data.append('no_of_guest', data.no_of_guest ? data.no_of_guest : datas?.no_of_guest);
    Data.append('no_of_bed', Number(data.no_of_bed) ? Number(data.no_of_bed) : datas?.no_of_bed);
    Data.append('no_of_bathroom', data.no_of_bathroom ? data.no_of_bathroom : datas?.no_of_bathroom);
    Data.append('no_of_room', data.no_of_room ? data.no_of_room : datas?.no_of_room);
    Data.append('size', data.size ? data.size : datas?.size);
    Data.append('unit_of_measure', data.unit_of_measure ? data.unit_of_measure : datas?.unit_of_measure);
    Data.append('addi_info', data.addi_info ? data.addi_info : datas?.addi_info);
    Data.append('instant_booking', data.instant_booking ? data.instant_booking : datas?.instant_booking);
    Data.append('nightly', data.nightly ? data.nightly : datas?.nightly);
    Data.append('per', data.per ? data.per : datas?.per);
    Data.append('weekend', data.weekend ? data.weekend : datas?.weekend);
    Data.append('apply_weekend_price', data.apply_weekend_price ? data.apply_weekend_price : datas?.apply_weekend_price);
    Data.append('weekly_seven_plus', data.weekly_seven_plus ? data.weekly_seven_plus : datas?.weekly_seven_plus);
    Data.append('weekly_thirty_plus', data.weekly_thirty_plus ? data.weekly_thirty_plus : datas?.weekly_thirty_plus);
    Data.append('addi_guest', data.addi_guest ? data.addi_guest :  datas?.addi_info);
    Data.append('addi_guest_price', data.addi_guest_price ? data.addi_guest_price : datas?.addi_guest_price);
    Data.append('no_of_addi_guest', data.no_of_addi_guest ? data.no_of_addi_guest : datas?.no_of_addi_guest);
    Data.append('clean_fee', data.clean_fee ? data.clean_fee : datas?.clean_fee);
    Data.append('clean_fee_daily', data.clean_fee_daily ? data.clean_fee_daily : datas?.clean_fee_daily);
    Data.append('clean_fee_per_stay', data.clean_fee_per_stay ? data.clean_fee_per_stay : datas?.clean_fee_per_stay);
    Data.append('city_fee_daily', data.city_fee_daily ? data.city_fee_daily : datas?.city_fee_daily);
    Data.append('city_fee_per_stay', data.city_fee_per_stay ? data.city_fee_per_stay : datas?.city_fee_per_stay);
    Data.append('city_fee', data.city_fee ? data.city_fee : datas?.city_fee);
    Data.append('security_deposit', data.security_deposit ? data.security_deposit : datas?.security_deposit);
    Data.append('tax', data.tax);
    Data.append('facilities', JSON.stringify(selectedCheckboxesOne));
    Data.append('amenities', JSON.stringify(selectedCheckboxes));
    Data.append('address', data.address ? data.address : datas?.address);
    Data.append('apt_suite', data.apt_suite ? data.apt_suite : datas?.apt_suite);
    Data.append('city', data.city ? data.city : datas?.city);
    Data.append('state', data.state ? data.state : datas?.state);
    Data.append('zipcode', data.zipcode ? data.zipcode : datas?.zipcode);
    Data.append('area', data.area ? data.area : datas?.area);
    Data.append('country', data.country ? data.country : datas?.country);
    Data.append('cancellation_policy', data.cancellation_policy ? data.cancellation_policy : datas?.cancellation_policy);
    Data.append('min_day_booking', data.min_day_booking ? data.min_day_booking : datas?.min_day_booking);
    Data.append('max_day_booking', data.max_day_booking ? data.max_day_booking : datas?.max_day_booking);
    Data.append('check_in_before', data.check_in_before ? data.check_in_before : datas?.check_in_before);
    Data.append('check_in_after', data.check_in_after ? data.check_in_after : datas?.check_in_after);
    Data.append('pet_allowed', data.pet_allowed ? data.pet_allowed : datas?.pet_allowed);
    Data.append('party_allowed', data.party_allowed ? data.party_allowed : datas?.party_allowed);
    Data.append('smoking_allowed', data.smoking_allowed ? data.smoking_allowed : datas?.smoking_allowed);
    Data.append('children_allowed', data.children_allowed ? data.children_allowed : datas?.children_allowed);
    Data.append('house_rule_addi_info', data.house_rule_addi_info ? data.house_rule_addi_info : datas?.house_rule_addi_info);

    bedroomsExtra?.map((item, index) => {
      Data.append(`bedrooms[${index}][id]`, item?.id ? item?.id : '');
      Data.append(`bedrooms[${index}][name]`, item?.name);
      Data.append(`bedrooms[${index}][no_of_guest]`, item?.no_of_guest);
      Data.append(`bedrooms[${index}][no_of_bed]`, item?.no_of_bed);
      Data.append(`bedrooms[${index}][bed_type]`, item?.bed_type);
    });

    images?.map((item, index) => {
      if ((item?.file && item?.id) || (item?.file && item?.id == undefined)) {
        Data.append(`images[${index}][id]`, item?.id ? item?.id : '');
        Data.append(`images[${index}][file]`, item?.file);
      }
    });

    extraServicePrices?.map((item, index) => {
      Data.append(`extra_service_prices[${index}][id]`, item?.id ? item?.id : '');
      Data.append(`extra_service_prices[${index}][name]`, item?.name);
      Data.append(`extra_service_prices[${index}][price]`, item?.price);
      Data.append(`extra_service_prices[${index}][type]`, item?.type);
    });

    try {
      await axios
        .post(`listing/${datas?.id}`, Data, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'X-Http-Method-Override': 'PUT',
          },
        })
        .then((response) => {
          if (response?.data?.status === true) {
            enqueueSnackbar('Listing Updated Successfully');
            reset();
            navigate(PATH_DASHBOARD.listing.listing);
          }
        });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  };

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs heading="Edit Favorite" links={[{ name: '', href: '' }]} />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <Grid item xs={12}>
                  <RHFTextField name="type_of_space" label="Type of Space" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="title" label="Title" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="description" label="Description" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="listing_type" label="Listing Type" placeholder="Listing Type">
                    <option value="Shop">Shop</option>
                    <option value="Hotel">Hotel</option>
                    <option value="Resturant">Resturant</option>
                    <option value="Fitness">Fitness</option>
                    <option value="Events">Events</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="no_of_bedroom" label="Number of Bedrooms" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="no_of_guest" label="Number of Guests" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="no_of_bed" label="Number of Beds" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="no_of_bathroom" label="Number of Bathrooms" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="no_of_room" label="Number of Rooms" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="size" label="Size" type="number" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="unit_of_measure" label="Unit of Measure" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="addi_info" label="Additional Information" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="instant_booking" label="Instant Booking" placeholder="Instant Booking">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="nightly" label="Nightly Price" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="per" label="Per" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="weekend" label="Weekend Price" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="apply_weekend_price" label="Apply weekend Price" placeholder="Apply weekend Price">
                    <option value="Monday">Monday</option>
                    <option value="Tuesday">Tuesday </option>
                    <option value="Wednesday">Wednesday</option>
                    <option value="Thursday">Thursday</option>
                    <option value="Friday">Friday</option>
                    <option value="Saturday">Saturday</option>
                    <option value="Sunday">Sunday</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="weekly_seven_plus" label="Weekly Price (7+ nights)" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="weekly_thirty_plus" label="Weekly Price (30+ nights)" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="addi_guest" label="Additional Guests" placeholder="Additional Guests">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="addi_guest_price" label="Additional Guest Price" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="clean_fee" label="Cleaning Fee" type="number" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="clean_fee_daily" label="Daily Cleaning Fee" placeholder="Daily Cleaning Fee">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect
                    name="clean_fee_per_stay"
                    label="Cleaning Fee per Stay"
                    placeholder="Cleaning Fee per Stay"
                  >
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="city_fee" label="City Fee" type="number" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="city_fee_daily" label="Daily City Fee" placeholder="Daily City Fee">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="city_fee_per_stay" label="City Fee per Stay" placeholder="City Fee per Stay">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="security_deposit" label="Security Deposit" type="number" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="tax" label="Tax" type="number" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="apt_suite" label="Apartment/Suite" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="city" label="City" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="state" label="State" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="zipcode" label="Zipcode" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="area" label="Area" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="country" label="Country" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="cancellation_policy" label="Cancellation Policy" placeholder="Cancellation Policy">
                    <option value="Shop">Shop</option>
                    <option value="Hotel">Hotel</option>
                    <option value="Resturant">Resturant</option>
                    <option value="Fitness">Fitness</option>
                    <option value="Events">Events</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="min_day_booking" label="Minimum Day Booking" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="max_day_booking" label="Maximum Day Booking" />
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="check_in_after" label="Check-in After" placeholder="Check-in After">
                    <option value={'8:00 PM'}>8:00PM</option>
                    <option value={'8:30 PM'}>8:30PM</option>
                    <option value={'9:00 PM'}>9:00PM</option>
                    <option value={'9:30 PM'}>9:30PM</option>
                    <option value={'10:00 PM'}>10:00PM</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="check_in_before" label="Check-in Before" placeholder="Check-in Before">
                    <option value={'8:00 PM'}>8:00PM</option>
                    <option value={'8:30 PM'}>8:30PM</option>
                    <option value={'9:00 PM'}>9:00PM</option>
                    <option value={'9:30 PM'}>9:30PM</option>
                    <option value={'10:00 PM'}>10:00PM</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="smoking_allowed" label="Smoking Allowed" placeholder="Smoking Allowed">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="pet_allowed" label="Pet Allowed" placeholder="Pet Allowed">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="party_allowed" label="Party Allowed" placeholder="Party Allowed">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFSelect name="children_allowed" label="Children Allowed" placeholder="Children Allowed">
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </RHFSelect>
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="house_rule_addi_info" label="House Rules Additional Info" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="address" label="Address" />
                </Grid>
                <Grid item xs={12}>
                  <RHFTextField name="no_of_addi_guest" label="Number of Additional Guests" />
                </Grid>
              </Box>
              {/* <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <h2 style={{ marginTop: '20px', marginBottom: '0px' }}>Aminities</h2>
                <Grid item xs={12}>
                  {tableData?.map((option) => (
                    <FormControlLabel
                      style={{ width: '220px' }}
                      key={option}
                      control={
                        <Checkbox
                          checked={selectedCheckboxes.includes(option?.name)}
                          onChange={handleCheckboxChange}
                          value={option?.name}
                        />
                      }
                      label={option?.name}
                    />
                  ))}
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                }}
              >
                <h2 style={{ marginTop: '20px', marginBottom: '0px' }}>Facility</h2>
                <Grid item xs={12}>
                  {Facility?.map((option) => (
                    <FormControlLabel
                      style={{ width: '220px' }}
                      key={option}
                      control={
                        <Checkbox
                          checked={selectedCheckboxesOne.includes(option?.name)}
                          onChange={handleCheckboxChangeOne}
                          value={option?.name}
                        />
                      }
                      label={option?.name}
                    />
                  ))}
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  marginTop: 4,
                  alignItems: 'center',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(4, 1fr)' },
                }}
              >
                {extraServicePrices?.map((service, index) => (
                  <>
                    <Grid item xs={12} key={index}>
                      <RHFTextField
                        label="Extra Service Prices Name"
                        variant="outlined"
                        placeholder="Extra Service Name"
                        value={service.name}
                        onChange={(e) => handleInputChangeOne(index, 'name', e.target.value, service?.id)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RHFTextField
                        label="Extra Service Prices Price (N)"
                        variant="outlined"
                        type="number"
                        placeholder="Extra Service Prices"
                        value={service.price}
                        onChange={(e) => handleInputChangeOne(index, 'price', e.target.value, service?.id)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RHFSelect
                        name="listing_type"
                        label="Extra Service Prices Type (N)"
                        placeholder="Extra Service Prices Type (N)"
                        value={service.type}
                        onChange={(e) => handleInputChangeOne(index, 'type', e.target.value, service?.id)}
                      >
                        <option value="Shop">Shop</option>
                        <option value="Hotel">Hotel</option>
                        <option value="Resturant">Resturant</option>
                        <option value="Fitness">Fitness</option>
                        <option value="Events">Events</option>
                      </RHFSelect>
                    </Grid>
                    {extraServicePrices?.length > 1 && (
                      <Grid item xs={12}>
                        <Button variant="contained" color="error" onClick={() => handleMinusClickOne(index, service)}>
                          Remove Service
                        </Button>
                      </Grid>
                    )}
                  </>
                ))}
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={handlePlusClickOne}>
                    Add Service
                    <i className="fas fa-sign-out" />
                  </Button>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  marginTop: 4,
                  alignItems: 'center',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(5, 1fr)' },
                }}
              >
                {bedroomsExtra?.map((service, index) => (
                  <>
                    <Grid item xs={12} key={index}>
                      <RHFTextField
                        label="Name of Bedroom 1"
                        variant="outlined"
                        placeholder="Name of Bedroom 1"
                        value={service.name}
                        onChange={(e) => handleInputChangeTwo(index, 'name', e.target.value, service?.id)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RHFTextField
                        label="Number of Guests"
                        variant="outlined"
                        type="number"
                        placeholder="Number of Guests"
                        value={service.no_of_guest}
                        onChange={(e) => handleInputChangeTwo(index, 'no_of_guest', e.target.value, service?.id)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RHFTextField
                        label="Number of Beds"
                        variant="outlined"
                        type="text"
                        placeholder="Number of Beds"
                        value={service.no_of_bed}
                        onChange={(e) => handleInputChangeTwo(index, 'no_of_bed', e.target.value, service?.id)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <RHFTextField
                        label="Number of Type"
                        variant="outlined"
                        type="text"
                        placeholder="Number of Type"
                        value={service.bed_type}
                        onChange={(e) => handleInputChangeTwo(index, 'bed_type', e.target.value, service?.id)}
                      />
                    </Grid>
                    {bedroomsExtra?.length > 1 && (
                      <Grid item xs={12}>
                        <Button variant="contained" color="error" onClick={() => handleMinusClickTwo(index, service)}>
                          Remove Service
                        </Button>
                      </Grid>
                    )}
                  </>
                ))}
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" onClick={handlePlusClickTwo}>
                    Add New Bedroom
                    <i className="fas fa-sign-out" />
                  </Button>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 2,
                  rowGap: 3,
                  marginTop: 4,
                  alignItems: 'center',
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                  {({ imageList, onImageUpload, isDragging, dragProps, onImageUpdate }) => (
                    <Box
                      sx={{
                        position: 'relative',
                        '& button': {
                          border: 'none',
                          background: isDragging ? '#2e3f6e' : 'none',
                          backgroundSize: 'cover',
                          backgroundColor: '#2e3f6e',
                          width: '100%',
                          height: 120,
                          color: isDragging ? 'white' : 'white',
                        },
                      }}
                    >
                      <Button onClick={onImageUpload} {...dragProps} fullWidth>
                        Click or Drop here <CloudUploadIcon className="ms-2" />
                      </Button>
                      <div className="d-flex flex-wrap justify-content-around align-items-center">
                        {imageList?.map((image, index) => (
                          <div key={index} className="image-item mt-3">
                            <img
                              src={image['data_url'] || image?.image}
                              alt=""
                              width="100"
                              height="100"
                              style={{ objectFit: 'cover', borderRadius: '50%', border: '1px solid #2e3f6e' }}
                            />
                            <div
                              className="image-item__btn-wrapper mt-2"
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                gap: '10px',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div
                                className="btn btn-danger cursor-pointer"
                                style={{ width: '90%' }}
                                onClick={() => onImageUpdate(index, image?.id)}
                              >
                                Update
                              </div>
                              <div
                                style={{ width: '90%' }}
                                className="btn btn-danger cursor-pointer"
                                onClick={() => onImageRemoves(index, image)}
                              >
                                Remove
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Box>
                  )}
                </ImageUploading>
              </Box> */}

              <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Edit Favorite
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
}
