/* eslint-disable no-unused-vars */
import { Icon } from '@iconify/react';
import PersonIcon from '@mui/icons-material/Person';
import GiteIcon from '@mui/icons-material/Gite';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import { Lock } from '@mui/icons-material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import MessageIcon from '@mui/icons-material/Message';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import BookIcon from '@mui/icons-material/Book';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import CancelIcon from '@mui/icons-material/Cancel';
import MailIcon from '@mui/icons-material/Mail';
import CategoryIcon from '@mui/icons-material/Category';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import { PATH_DASHBOARD, PATH_ROLE_LOGIN } from '../../../routes/paths';

const navConfig = [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard/home', icon: <Icon icon="ri:dashboard-line" /> },
      { title: 'Profile', path: '/dashboard/editadmin', icon: <PeopleAltIcon /> },
      { title: 'My Listings', path: PATH_DASHBOARD.listing.listing, icon: <FormatListBulletedIcon /> },
      { title: 'My Bookings', path: PATH_DASHBOARD.booking.booking, icon: <Icon icon="ri:dashboard-line" /> },
      { title: 'My Trips', path: PATH_DASHBOARD.trip.trip, icon: <RamenDiningIcon /> },
      { 
        title: 'AirMail\u2122', // Unicode for ™
        path: PATH_DASHBOARD.message.message, 
        icon: <MessageIcon /> 
      },
      { title: 'Payouts', path: PATH_DASHBOARD.payouts.payouts, icon: <RamenDiningIcon /> },
      { title: 'Invoices', path: PATH_DASHBOARD.invoices.invoices, icon: <RamenDiningIcon /> },
      { title: 'Favorites', path: PATH_DASHBOARD.favorite.favorite, icon: <Icon icon="mdi:favorite" /> },
      { title: 'Logout', path: PATH_ROLE_LOGIN.admin, icon: <Lock /> },

      { title: 'Users', path: PATH_DASHBOARD.spell.spell, icon: <PersonIcon /> },
      // { title: 'Host', path: PATH_DASHBOARD.host.host, icon: <GiteIcon /> },
      // { title: 'Facilities', path: PATH_DASHBOARD.facility.facility, icon: <PeopleAltIcon /> },
      { title: 'Features', path: PATH_DASHBOARD.amenity.amenity, icon: <RamenDiningIcon /> },
      { title: 'Email Management', path: PATH_DASHBOARD.emailmanagement.emailmanagement, icon: <MailIcon /> },

      { title: 'Squawks', path: PATH_DASHBOARD.message.blog, icon: <BookIcon /> },
      { title: 'Pages', path: PATH_DASHBOARD.page.page, icon: <ImportContactsIcon /> },
      { title: 'Reviews', path: PATH_DASHBOARD.review.review, icon: <StarHalfIcon /> },
      { title: 'Comments', path: PATH_DASHBOARD.comments.comments, icon: <MarkUnreadChatAltIcon /> },
      { title: 'Media', path: PATH_DASHBOARD.media.media, icon: <PermMediaIcon /> },
      {
        title: 'Cancelation Policies',
        path: PATH_DASHBOARD.cancellationPolicy.cancellationPolicy,
        icon: <CancelIcon />,
      },
      {
        title: 'Categories',
        path: PATH_DASHBOARD.category.category,
        icon: <CategoryIcon />,
      },
      {
        title: 'Tags',
        path: PATH_DASHBOARD.tags.tags,
        icon: <LocalOfferIcon />,
      },
    ],
  },
];

export default navConfig;
