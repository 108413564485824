/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../utils/axios';

const AuthorModal = (props) => {
  const { setUserId, handleSubmit, formData, showModalAuthor, setShowModalAuthor, isSubmitting } = props || {};

  const [user, setUser] = useState('');

  const allUser = async () => {
    try {
      const response = await axiosInstance.get('/user');
      console.log(response?.data?.data, 'response');
      setUser(response?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    allUser();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <p className="fs-6">Author</p>
        <button className="fs-6 bg-transparent text-capitalize" style={{ color: '#54c4d9' }} onClick={() => setShowModalAuthor(true)}>
          {formData}
        </button>
      </div>

      {showModalAuthor && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div
            className="modal-dialog"
            role="document"
            style={{
              margin: 'auto',
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select Author</h5>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-start mb-2">
                  <select className="form-control" onChange={(e) => setUserId(e.target.value)}>
                    {user?.map((user, index) => (
                      <option value={user?.id} key={index}>
                        {user?.username}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-secondary" onClick={() => setShowModalAuthor(false)}>
                  Close
                </button>
                <button disabled={isSubmitting} className="btn btn-primary" onClick={() => handleSubmit(true)}>
                  {isSubmitting ? 'Submitting...' : 'Save changes'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthorModal;
