/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import axiosInstance from '../../../utils/axios';

const StatusModal = (props) => {
  const { id, formData, fetchBlog } = props || {};
  const [showModal, setShowModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [schedulePassword, setSchedulePassword] = useState('');

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };
  console.log(selectedStatus, scheduleDate, scheduleTime, 'scheduleTime');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('id', id);
    formData.append('status', selectedStatus);
    try {
      const res = await axiosInstance.post(`/blog-status-change`, formData);
      console.log(res);
      fetchBlog();
      setShowModal(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <p className="fs-6">Status :</p>
        <button
          className="fs-6 bg-transparent text-capitalize"
          style={{ color: '#54c4d9' }}
          onClick={() => setShowModal(true)}
        >
          {formData}
        </button>
      </div>

      {showModal && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div
            className="modal-dialog"
            role="document"
            style={{
              margin: 'auto',
            }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Select Status</h5>
              </div>
              <div className="modal-body">
                <div className="d-flex justify-content-start my-2">
                  <input
                    type="radio"
                    value="Draft"
                    checked={selectedStatus === 'Draft'}
                    onChange={handleStatusChange}
                  />
                  <label htmlFor="Pending" className="ms-2">
                    Draft
                  </label>
                </div>
                <div className="d-flex justify-content-start mb-2">
                  <input
                    type="radio"
                    value="Pending"
                    checked={selectedStatus === 'Pending'}
                    onChange={handleStatusChange}
                  />
                  <label htmlFor="Pending" className="ms-2">
                    Pending
                  </label>
                </div>
                <div className="d-flex justify-content-start my-2">
                  <input
                    type="radio"
                    value="Published"
                    checked={selectedStatus === 'Published'}
                    onChange={handleStatusChange}
                  />
                  <label htmlFor="Pending" className="ms-2">
                    Published
                  </label>
                </div>
                <div className="d-flex justify-content-start my-2">
                  <input
                    type="radio"
                    value="Private"
                    checked={selectedStatus === 'Private'}
                    onChange={handleStatusChange}
                  />
                  <label htmlFor="Pending" className="ms-2">
                    Private
                  </label>
                </div>
                <div className="d-flex justify-content-start my-2">
                  <input
                    type="radio"
                    value="Scheduled"
                    checked={selectedStatus === 'Scheduled'}
                    onChange={handleStatusChange}
                  />
                  <label htmlFor="Pending" className="ms-2">
                    Scheduled
                  </label>
                </div>
                <div className="d-flex justify-content-start my-2">
                  <input
                    type="radio"
                    value="Password"
                    checked={selectedStatus === 'Password'}
                    onChange={handleStatusChange}
                  />
                  <label htmlFor="Password" className="ms-2">
                    Password
                  </label>
                </div>
                {selectedStatus === 'Scheduled' && (
                  <>
                    <div className="d-flex justify-content-between align-items-center gap-4 mt-2">
                      <div className="d-flex justify-content-start w-100 flex-column">
                        <label htmlFor="Time" className="text-start">
                          Date:
                        </label>
                        <input
                          type="date"
                          className="form-control w-100"
                          value={scheduleDate}
                          onChange={(e) => setScheduleDate(e.target.value)}
                        />
                      </div>
                      <div className="d-flex justify-content-start w-100 flex-column">
                        <label htmlFor="Time" className="text-start">
                          Time:
                        </label>
                        <input
                          type="time"
                          className="form-control w-100"
                          value={scheduleTime}
                          onChange={(e) => setScheduleTime(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}
                {selectedStatus === 'Password' && (
                  <>
                    <div className="d-flex justify-content-between align-items-center gap-4 mt-2">
                      <div className="d-flex justify-content-start w-100 flex-column">
                        <label htmlFor="Time" className="text-start">
                          Password:
                        </label>
                        <input
                          type="password"
                          className="form-control w-100"
                          value={schedulePassword}
                          onChange={(e) => setSchedulePassword(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                  Close
                </button>
                <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StatusModal;
