/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable arrow-body-style */
import { useState, useEffect, useMemo } from 'react';
import MaterialReactTable from 'material-react-table';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { Delete, RemoveRedEyeOutlined } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
// import AddIcon from '@mui/icons-material/Add';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Container, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import axiosInstance from '../../../utils/axios';
import Page from '../../../components/Page';
import Iconify from '../../../components/Iconify';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import LoadingScreen from '../../../components/LoadingScreen';

export default function Listing() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loader, setloader] = useState(false);
  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        size: 10,
      },
      {
        accessorKey: 'heading',
        header: 'Title',
        size: 5,
      },
      {
        accessorKey: 'user_name',
        header: 'Author',
        size: 5,
      },
      {
        accessorKey: 'blog_category.name',
        header: 'Categories',
        size: 5,
      },
      {
        accessorKey: 'blog_tag.name',
        header: 'Tags',
        size: 5,
      },
      {
        accessorKey: 'total_comments',
        header: 'No. of Comments',
        size: 5,
        Cell: ({ row }) => (
          <button
            style={{
              backgroundColor: 'transparent',
              color: '#000',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
              width: '100%',
              textAlign: 'center',
              height: '100%',
            }}
            onClick={() => {
              navigate(PATH_DASHBOARD.comments.commentfilter(row.original?.id), { state: row.original });
            }}
          >
            {row.original?.total_comments}
          </button>
        ),
      },
      {
        accessorKey: 'date',
        header: 'Date',
        size: 5,
      },
      {
        accessorKey: 'time',
        header: 'Time',
        size: 5,
      },
    ],
    []
  );

  const [tableData, setTableData] = useState([]);
  async function handleDublicate(rowdata) {
    try {
      await axiosInstance.post(`blog`, rowdata).then((response) => {
        if (response?.data?.status === true) {
          enqueueSnackbar('Blog Deleted Successfully');
          AllListings();
          console.log(response, 'responsepush');
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }
  async function AllListings() {
    setloader(true);
    const data = await axiosInstance.get('blog');
    setTableData(data?.data?.data);
    setloader(false);
  }

  useEffect(() => {
    AllListings();
  }, []);

  async function handleDelete(rowdata) {
    try {
      await axiosInstance.delete(`blog/${rowdata}`).then((response) => {
        if (response?.data?.status === true) {
          enqueueSnackbar('Blog Deleted Successfully');
          AllListings();
        }
      });
    } catch (error) {
      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
      console.error(error);
    }
  }

  const [tags, setTags] = useState([]);
  const GetTags = async () => {
    const response = await axiosInstance.get(`/blog-tag`);
    setTags(response.data?.data || []);
  };
  const [category, setCategory] = useState([]);
  const GetCategory = async () => {
    const response = await axiosInstance.get(`/blog-category`);
    setCategory(response.data?.data || []);
  };

  useEffect(() => {
    GetTags();
    GetCategory();
  }, []);

  return (
    <>
      {loader && <LoadingScreen />}
      <Page title="Squawks">
        <Container maxWidth="lg">
          <HeaderBreadcrumbs
            heading="Squawks"
            links={[{ name: '', href: '' }]}
            action={
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                component={RouterLink}
                to={PATH_DASHBOARD.message.addblog}
              >
                New Blog
              </Button>
            }
          />
          <MaterialReactTable
            columns={columns}
            data={tableData}
            enableRowActions
            renderRowActions={({ row }) => (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'nowrap',
                  gap: '5px',
                  justifyContent: 'flex-center',
                }}
              >
                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    window.open(PATH_DASHBOARD.message.viewblog(row.original.id), '_blank', 'noopener,noreferrer');
                  }}
                >
                  <RemoveRedEyeOutlined />
                </IconButton>

                <IconButton
                  color="primary"
                  sx={{
                    border: '1px solid',
                    borderColor: 'primary.main',
                  }}
                  onClick={() => {
                    navigate(PATH_DASHBOARD.message.editblog(row.original.id), { state: row.original });
                  }}
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    handleDelete(row.original.id);
                  }}
                >
                  <Delete />
                </IconButton>
                <IconButton
                  color="error"
                  sx={{
                    border: '1px solid',
                    borderColor: 'error.main',
                  }}
                  onClick={() => {
                    handleDublicate(row.original);
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            )}
            positionActionsColumn="last"
          />
        </Container>
      </Page>
    </>
  );
}
