/* eslint-disable react/no-danger */
/* eslint-disable import/order */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../utils/axios';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

const ViewBlog = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axiosInstance.get(`blog/${id}`);
        setBlog(response?.data.blog);
        console.log(response.data.blog, 'blog');
      } catch (error) {
        setError('Error fetching blog');
        console.error('Error fetching blog:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchBlog();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      {/* <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Grid container sx={{ display: 'flex', alignItems: 'center', mt: 1.5 }} spacing={1}>
            <Grid item sx={{ mt: 2 }} xs={12} md={5}>
              Heading
            </Grid>
            <Grid item sx={{ mt: 2 }} xs={12} md={5}></Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid sx={{ background: '#fff', padding: 3, borderRadius: 1 }}>
        <Typography sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>Username :</Typography>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>{blog?.user_name}</Typography>
        </Typography>
        <Typography sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>Heading :</Typography>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>{blog?.heading}</Typography>
        </Typography>
        <Typography sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>Subheading :</Typography>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>{blog?.sub_heading}</Typography>
        </Typography>
        <Typography sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>Description :</Typography>
          <Typography
            sx={{ color: '#000', fontSize: '18px !important' }}
            dangerouslySetInnerHTML={{ __html: blog?.description }}
          />
        </Typography>
 
        <Typography sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>Id</Typography>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>{blog?.id}</Typography>
        </Typography>
        <Typography sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>News</Typography>
          <Typography sx={{ color: '#000', fontSize: '18px !important' }}>{blog?.news_by_topic}</Typography>
        </Typography>
      </Grid>
      {/* <div style={cardContainerStyle}>
        <div style={cardStyle}>
          <h2 style={headingStyle}>{blog.heading}</h2>
          <h4 style={subHeadingStyle}>{blog.sub_heading}</h4>
          <div style={descriptionStyle} dangerouslySetInnerHTML={{ __html: blog.description }} />
        </div>
      </div> */}
    </>
  );
};

// const cardContainerStyle = {
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
//   height: '100vh',
//   backgroundColor: '#f9f9f9',
// };

// const cardStyle = {
//   maxWidth: '600px',
//   padding: '20px',
//   border: '1px solid #ccc',
//   borderRadius: '10px',
//   backgroundColor: '#fff',
//   boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
// };

// const headingStyle = {
//   marginBottom: '10px',
// };

// const subHeadingStyle = {
//   marginBottom: '20px',
//   color: '#555',
// };

// const descriptionStyle = {
//   fontSize: '16px',
//   lineHeight: '1.6',
// };

export default ViewBlog;
