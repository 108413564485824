// /* eslint-disable prefer-template */
// /* eslint-disable no-alert */
// /* eslint-disable import/order */
// /* eslint-disable jsx-a11y/label-has-associated-control */
// import React, { useEffect, useRef, useState } from 'react';
// import JoditEditor from 'jodit-react';
// import axiosInstance from '../../../utils/axios';
// import { useNavigate } from 'react-router-dom';

// const CustomForm = () => {
//   const editor = useRef(null);
//   const [formData, setFormData] = useState({
//     heading: '',
//     sub_heading: '',
//     description: '',
//     tag_id: [],
//     category_id: [],
//   });

//   console.log(formData, 'fro');

//   const [errors, setErrors] = useState({
//     heading: '',
//     sub_heading: '',
//     description: '',
//     // news_by_topic: '',
//     tag_id: '',
//     category_id: '',
//   });

//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const navigate = useNavigate();

//   const validate = () => {
//     const newErrors = {};
//     if (!formData.heading) newErrors.heading = 'Heading is required';
//     if (!formData.sub_heading) newErrors.sub_heading = 'Sub-Heading is required';
//     if (!formData.description) newErrors.description = 'Description is required';
//     if (!formData.tag_id) newErrors.tag_id = 'Tag is required';
//     if (!formData.category_id) newErrors.category_id = 'Category is required';
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };
//   const handleChangeTag = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };
//   console.log(formData, 'handleChangeTag');

//   const handleDescriptionChange = (value) => {
//     setFormData({
//       ...formData,
//       description: value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (validate()) {
//       setIsSubmitting(true);
//       try {
//         const res = await axiosInstance.post('blog', formData);
//         console.log(res);
//         navigate('/dashboard/blog'); // Navigate to the desired route on success
//       } catch (error) {
//         console.error('Error:', error);
//         alert('Failed to add blog: ' + (error.response?.data?.message || error.message));
//       } finally {
//         setIsSubmitting(false);
//       }
//     }
//   };

//   const [tags, setTags] = useState([]);
//   const GetTags = async () => {
//     const response = await axiosInstance.get(`/blog-tag`);
//     setTags(response.data?.data || []);
//   };
//   const [category, setCategory] = useState([]);
//   const GetCategory = async () => {
//     const response = await axiosInstance.get(`/blog-category`);
//     setCategory(response.data?.data || []);
//   };

//   useEffect(() => {
//     GetTags();
//     GetCategory();
//   }, []);
//   const formContainerStyle = {
//     maxWidth: '60%',
//     margin: '0',
//     padding: '20px',
//     border: '1px solid #ccc',
//     borderRadius: '10px',
//   };

//   const headingStyle = {
//     textAlign: 'center',
//   };

//   const formGroupStyle = {
//     marginBottom: '15px',
//   };

//   const labelStyle = {
//     display: 'block',
//     marginBottom: '5px',
//   };

//   const flexContainerStyle = {
//     display: 'flex',
//     justifyContent: 'space-between',
//   };

//   const flexItemStyle = {
//     flex: '1',
//     marginRight: '10px',
//   };

//   const inputStyle = {
//     width: '100%',
//     padding: '8px',
//     boxSizing: 'border-box',
//     border: '1px solid #ccc',
//     borderRadius: '5px',
//   };

//   const errorStyle = {
//     color: 'red',
//     fontSize: '0.875em',
//   };

//   const buttonStyle = {
//     display: 'block',
//     width: '100%',
//     padding: '10px',
//     marginTop: '60px', // Add margin to the button
//     backgroundColor: isSubmitting ? '#ccc' : '#2E3F6E',
//     color: 'white',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: isSubmitting ? 'not-allowed' : 'pointer',
//   };
//   const editorConfig = {
//     readonly: false, // all options from https://xdsoft.net/jodit/doc/
//     toolbarAdaptive: false,
//     height: 400,
//     uploader: {
//       insertImageAsBase64URI: true, // Use base64 to insert images
//     },
//     buttons: [
//       'bold',
//       'italic',
//       'underline',
//       'strikethrough',
//       'ul',
//       'ol',
//       'outdent',
//       'indent',
//       'font',
//       'fontsize',
//       'brush',
//       'paragraph',
//       'image',
//       'table',
//       'link',
//       'align',
//       'undo',
//       'redo',
//       'eraser',
//       'copyformat',
//       'hr',
//     ],
//   };

//   return (
//     <div style={formContainerStyle}>
//       <h1 style={headingStyle} className="pb-3">
//         Create New Blog
//       </h1>
//       <form onSubmit={handleSubmit}>
//         <div style={flexContainerStyle} className="mt-3">
//           <div style={flexItemStyle}>
//             <label className="text-start" style={{ textAlign: 'left !important' }}>
//               Choose Categories:
//             </label>
//             <select className="form-control" onChange={handleChange} id="category_id" name="category_id">
//               {category.map((e, i) => (
//                 <option key={i} value={e?.id}>
//                   {e?.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div style={flexItemStyle}>
//             <label className="text-start">Choose Tags:</label>
//             <select className="form-control" onChange={handleChangeTag} id="tag_id" name="tag_id">
//               {tags.map((e, i) => (
//                 <option key={i} value={e?.id}>
//                   {e?.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//         {errors.news_by_topic && <span style={errorStyle}>{errors.news_by_topic}</span>}
//         <div className="pt-3" style={formGroupStyle}>
//           {/* <div style={flexContainerStyle}>
//             <div style={flexItemStyle}> */}
//           <label htmlFor="heading" className="text-start" style={labelStyle}>
//             Title :
//           </label>
//           <input
//             type="text"
//             id="heading"
//             name="heading"
//             value={formData.heading}
//             onChange={handleChange}
//             style={inputStyle}
//           />
//           {errors.heading && <span style={errorStyle}>{errors.heading}</span>}
//           {/* </div>
//             <div style={{ ...flexItemStyle, marginRight: '0' }}> */}
//           <label htmlFor="sub_heading" className="text-start pt-3" style={labelStyle}>
//             Subtitle:
//           </label>
//           <input
//             type="text"
//             id="sub_heading"
//             name="sub_heading"
//             value={formData.sub_heading}
//             onChange={handleChange}
//             style={inputStyle}
//           />
//           {errors.sub_heading && <span style={errorStyle}>{errors.sub_heading}</span>}
//         </div>
//         {/* </div> */}
//         {/* </div> */}

//         <div style={formGroupStyle}>
//           <label className="pt-3" htmlFor="description" style={labelStyle}>
//             Description:
//           </label>
//           <JoditEditor
//             ref={editor}
//             value={formData.description}
//             // onChange={handleDescriptionChange}
//             config={editorConfig} // tabIndex is optional
//             onBlur={(newContent) => handleDescriptionChange(newContent)}
//             // You can add more props here as needed
//           />
//           {errors.description && <span style={errorStyle}>{errors.description}</span>}
//         </div>
//         <button type="submit" style={buttonStyle} disabled={isSubmitting}>
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// };

// export default CustomForm;

/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-template */
/* eslint-disable no-alert */
/* eslint-disable import/order */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../../../utils/axios';
import { useNavigate, useParams } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import StatusModal from './StatusModal';
import AuthorModal from './AuthorModal';
import { FormControlLabel, Switch, Autocomplete, TextField, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const AddBlog = () => {
  const editor = useRef(null);
  const [userId, setUserId] = useState('');
  const [showModalAuthor, setShowModalAuthor] = useState(false);
  const [formData, setFormData] = useState({
    heading: '',
    sub_heading: '',
    news_by_topic: [],
    tag: [],
    description: '',
    sticky: false,
    comment: false,
    pings: false,
    pingbacks: false,
    trackbacks: false,
    google_analytics_tracking: false,
    discussion: false,
  });

  const [errors, setErrors] = useState({
    heading: '',
    sub_heading: '',
    news_by_topic: '',
    tag: '',
    description: '',
    sticky: null,
    comment: null,
    pings: null,
    pingbacks: null,
    trackbacks: null,
    google_analytics_tracking: null,
    discussion: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  // const fetchBlog = async () => {
  //   try {
  //     const response = await axiosInstance.get(`blog/${id}`);
  //     setFormData({
  //       heading: response?.data?.blog?.heading,
  //       sub_heading: response?.data?.blog?.sub_heading,
  //       description: response?.data?.blog?.description,
  //       news_by_topic: response?.data?.blog?.news_by_topic,
  //       tag: response?.data?.blog?.tag,
  //       user_name: response?.data?.blog?.user_name,
  //       status: response?.data?.blog?.status,
  //       discussion: response?.data?.blog?.discussion,
  //     });
  //   } catch (error) {
  //     console.error('Error fetching blog:', error);
  //   }
  // };

  // useEffect(() => {
  //   if (id) {
  //     fetchBlog();
  //   }
  // }, []);

  const validate = () => {
    const newErrors = {};
    if (!formData.heading) newErrors.heading = 'Heading is required';
    if (!formData.sub_heading) newErrors.sub_heading = 'Sub-Heading is required';
    if (!formData.description) newErrors.description = 'Description is required';
    // if (!formData.news_by_topic) newErrors.news_by_topic = 'Topic is required';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleDescriptionChange = (value) => {
    setFormData({
      ...formData,
      description: value,
    });
  };

  const handleSubmit = async (type) => {
    if (validate()) {
      setIsSubmitting(true);
      try {
        const formDatas = new FormData();
        formDatas.append('heading', formData.heading);
        formDatas.append('sub_heading', formData.sub_heading);
        formDatas.append('description', formData.description);
        formData?.tag?.map((e) => {
          return formDatas.append('tag_id', e.id);
        });
        formData?.news_by_topic?.map((e) => {
          return formDatas.append('category_id', e.id);
        });
        formDatas.append('sticky', formData.sticky);
        formDatas.append('allow_comment', formData.comment ? 1 : 0);
        formDatas.append('pings', formData.pings);
        formDatas.append('pingbacks', formData.pingbacks);
        formDatas.append('trackbacks', formData.trackbacks);
        formDatas.append('google_analytics_tracking', formData.google_analytics_tracking);
        formDatas.append('discussion', formData.discussion);
        if (userId) {
          formDatas.append('user_id', userId);
        }
        formDatas.append('_method', 'POST');
        const res = await axiosInstance.post(`/blog`, formDatas);
        console.log(res);
        navigate('/dashboard/blog');
      } catch (error) {
        console.error('Error:', error);
        alert('Failed to save blog: ' + (error.response?.data?.message || error.message));
      } finally {
        setIsSubmitting(false);
      }
    }
  };
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleChangeTag = (selectedTags) => {
    setFormData({
      ...formData,
      tag: selectedTags,
    });
  };
  const handleChangeCategory = (selectedCategory) => {
    setFormData({
      ...formData,
      news_by_topic: selectedCategory,
    });
  };

  const [tags, setTags] = useState([]);
  const GetTags = async () => {
    const response = await axiosInstance.get(`/blog-tag`);
    setTags(response.data?.data || []);
  };

  const [category, setCategory] = useState([]);
  const GetCategory = async () => {
    const response = await axiosInstance.get(`/blog-category`);
    setCategory(response.data?.data || []);
  };

  useEffect(() => {
    GetTags();
    GetCategory();
  }, []);

  const formContainerStyle = {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#fff',
  };

  const headingStyle = {
    textAlign: 'center',
  };

  const formGroupStyle = {
    marginBottom: '15px',
  };

  const labelStyle = {
    display: 'block',
    marginBottom: '5px',
  };

  const flexContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '10px',
  };

  const flexItemStyle = {
    width: '50%',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    boxSizing: 'border-box',
    border: '1px solid #ccc',
    borderRadius: '5px',
  };

  const errorStyle = {
    color: 'red',
    fontSize: '0.875em',
  };

  const buttonStyle = {
    display: 'block',
    width: '100%',
    padding: '10px',
    marginTop: '60px', // Add margin to the button
    backgroundColor: isSubmitting ? '#ccc' : '#2E3F6E',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: isSubmitting ? 'not-allowed' : 'pointer',
  };

  const editorConfig = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    toolbarAdaptive: false,
    height: 400,
    uploader: {
      insertImageAsBase64URI: true, // Use base64 to insert images
    },
    buttons: [
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'ul',
      'ol',
      'outdent',
      'indent',
      'font',
      'fontsize',
      'brush',
      'paragraph',
      'image',
      'table',
      'link',
      'align',
      'undo',
      'redo',
      'eraser',
      'copyformat',
      'hr',
    ],
  };

  return (
    <div className="row justify-content-around">
      <div className="col-md-8 col-12" style={formContainerStyle}>
        <h1 style={headingStyle} className="pb-3">
          {id ? 'Edit Squawk' : 'Create New Squawk'}
        </h1>
        <div>
          <div style={flexContainerStyle} className="mt-3">
            <div style={flexItemStyle}>
              <label className="text-start" style={{ textAlign: 'left !important' }}>
                Choose Categories :
              </label>
              <Autocomplete
                sx={{ width: '100%' }}
                multiple
                id="news_by_topic"
                options={category}
                name="news_by_topic"
                freeSolo
                getOptionLabel={(option) => option.name || option}
                onChange={(e, value) => handleChangeCategory(value)}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <MenuItem
                    key={option.id || option}
                    value={option.id}
                    sx={{ justifyContent: 'space-between' }}
                    {...props}
                  >
                    {option.name || option}
                    {selected ? <CheckIcon color="info" /> : null}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Categories" placeholder="Select Categories" />
                )}
              />
            </div>
            <div style={flexItemStyle}>
              <label className="text-start">Choose Tags :</label>
              <Autocomplete
                sx={{ width: '100%' }}
                multiple
                id="tag"
                options={tags}
                name="tag_id"
                freeSolo
                getOptionLabel={(option) => option.name || option}
                onChange={(e, value) => handleChangeTag(value)}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <MenuItem
                    key={option.id || option}
                    value={option.id || option}
                    sx={{ justifyContent: 'space-between' }}
                    {...props}
                  >
                    {option.name || option}
                    {selected ? <CheckIcon color="info" /> : null}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Tags" placeholder="Select Tags" />
                )}
              />
            </div>
          </div>

          <div style={formGroupStyle}>
            <div className="" style={flexContainerStyle}>
              <div style={flexItemStyle}>
                <label className="text-start pt-3" htmlFor="heading" style={labelStyle}>
                  Title :
                </label>
                <input
                  type="text"
                  id="heading"
                  name="heading"
                  value={formData.heading}
                  onChange={handleChange}
                  style={inputStyle}
                />
                {errors.heading && <span style={errorStyle}>{errors.heading}</span>}
              </div>
              <div style={flexItemStyle}>
                <label className="text-start pt-3" htmlFor="sub_heading" style={labelStyle}>
                  Subtitle :
                </label>
                <input
                  type="text"
                  id="sub_heading"
                  name="sub_heading"
                  value={formData.sub_heading}
                  onChange={handleChange}
                  style={inputStyle}
                />
                {errors.sub_heading && <span style={errorStyle}>{errors.sub_heading}</span>}
              </div>
            </div>
          </div>
          <div className="" style={formGroupStyle}>
            <label htmlFor="description" style={labelStyle}>
              Description :
            </label>
            <JoditEditor
              ref={editor}
              value={formData.description}
              config={editorConfig}
              onBlur={(newContent) => handleDescriptionChange(newContent)}
            />
            {errors.description && <span style={errorStyle}>{errors.description}</span>}
          </div>
          <button style={buttonStyle} disabled={isSubmitting} onClick={() => handleSubmit(false)}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
      <div className="col-md-3 col-12 bg-white h-100" style={formContainerStyle}>
        <StatusModal id={id} formData={formData.status} />
        <AuthorModal
          userId={userId}
          setUserId={setUserId}
          handleSubmit={handleSubmit}
          formData={formData.user_name}
          showModalAuthor={showModalAuthor}
          setShowModalAuthor={setShowModalAuthor}
          isSubmitting={isSubmitting}
        />
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Time :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            11:20
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Date :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            01/11/2023
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Slug :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            {id}
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Allow Comments :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            <input
              type="checkbox"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              style={{ width: '20px', height: '20px' }}
            />
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Allow Pings :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            <input
              type="checkbox"
              name="pings"
              value={formData.pings}
              onChange={handleChange}
              style={{ width: '20px', height: '20px' }}
            />
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Make this post sticky :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            <input
              type="checkbox"
              name="sticky"
              value={formData.sticky}
              onChange={handleChange}
              style={{ width: '20px', height: '20px' }}
            />
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Pingbacks :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            <input
              type="checkbox"
              name="pingbacks"
              value={formData.pingbacks}
              onChange={handleChange}
              style={{ width: '20px', height: '20px' }}
            />
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Trackbacks :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }} y>
            <input
              type="checkbox"
              name="trackbacks"
              value={formData.trackbacks}
              onChange={handleChange}
              style={{ width: '20px', height: '20px' }}
            />
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">Google Analytics Tracking :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            <input
              type="checkbox"
              name="google_analytics_tracking"
              value={formData.google_analytics_tracking}
              onChange={handleChange}
              style={{ width: '20px', height: '20px' }}
            />
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6">DISCUSSION :</p>
          <p className="fs-6" style={{ color: '#54c4d9' }}>
            <FormControlLabel
              name="discussion"
              value={formData.discussion}
              control={<Switch />}
              style={{ padding: 0 }}
              label=""
              onChange={handleChange}
            />

            {/* <input
              type="checkbox"
              name="sticky"
              
              
              style={{ width: '20px', height: '20px' }}
            /> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
